<template>
  <v-container class="pa-0" style="max-width: 100%" fill-height tag="section">
    <v-slide-y-transition appear>
      <v-row class="pa-0" style="height: 100%">
        <v-col cols="12" md="6" lg="6" class="pa-0">
          <v-card flat min-width="100%" style="height: 100%">
            <v-row
                no-gutters
                height="100%"
                style="
                min-height: 90%;
                max-width: 600px;
                min-width: 400px;
                height: 90%;
              "
                class="mx-auto"
            >
              <v-col align-self="center" align="center" class="pa-10">
                <v-card-text dir="auto" class="text-center" style="width: 100%">
                  <div style="font-size: 30px">
                    <h5 class="primary--text">{{ $t("appName") }}</h5>
                  </div>
                </v-card-text>

                <v-form v-on:submit.prevent="login" class="pa-10">
                  <v-card-text class="text-center">
                    <settings/>
                    <v-text-field
                        :label="$t('login.email')"
                        v-model="email"
                        :rules="[(value) => !!value || $t('ThisFieldIsRequired')]"
                        outlined
                        placeholder=" "
                        persistent-placeholder
                        :name="getId"
                    />
                    <v-text-field
                        placeholder=" "
                        persistent-placeholder
                        :label="$t('login.password')"
                        v-model="password"
                        :rules="[(value) => !!value || $t('ThisFieldIsRequired')]"
                        :type="show1 ? 'text' : 'password'"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show1 = !show1"
                        outlined
                        :name="getId"
                    />
                  </v-card-text>
                  <v-col class="text-center">
                    <v-btn
                        :loading="loading"
                        @click.prevent="login()"
                        type="submit"
                        large
                        block
                        color="primary darken-1"
                        ref="sendReply"
                    >
                      {{ $t("login.signIn") }}
                    </v-btn>
                  </v-col>
                </v-form>
              </v-col>
            </v-row>
            <v-footer dark padless absolute>
              <v-card class="flex" flat tile>
                <v-card-title class="grey darken-3">
                  <strong class="subheading">
                    <a text href="https://kf.iq" target="_blank">
                      <v-img
                          width="200"
                          src="../../assets/Kafel-logo.svg"
                          lazy-src="../../assets/Kafel-logo.svg"
                          style="width: 200px; height: 52px"
                      >
                      </v-img>
                    </a>
                  </strong>
                  <v-spacer></v-spacer>

                  <v-btn
                      v-for="icon in icons"
                      :key="icon.icon"
                      :href="icon.link"
                      target="_blank"
                      class="mx-4"
                      dark
                      icon
                  >
                    <v-icon size="24px">
                      {{ icon.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-title>
              </v-card>
            </v-footer>
          </v-card>
        </v-col>
        <v-col
            cols="12"
            md="6"
            lg="6"
            class="hidden-xs-and-up hidden-sm-and-down"
        >
          <v-img
              max-width="60%"
              style="margin: 75px auto"
              src="../../assets/login-image.png"
          ></v-img>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import Settings from "../../components/Settings.vue";
import {v4} from "uuid";

export default {
  components: {Settings},
  data() {
    return {
      show1: false,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        min: (v) =>
            v.length >= 6 || this.$t("login.PasswordMustContain6OrMoreElements"),
      },
      email: "",
      password: "",
      loading: false,
      rtl: localStorage.rtl === "true",
      dark: localStorage.dark === "true",
      icons: [
        {
          icon: "mdi-instagram",
          link: "https://instagram.com/alkafeel.center/",
        },
        {
          icon: "mdi-facebook",
          link: "https://www.facebook.com/alkafeelcenter/",
        },
      ],
    };
  },
  mounted() {
    this.$vuetify.rtl = this.rtl;
    this.$vuetify.theme.dark = this.dark;
    var lang = this.rtl ? "ar" : "en";
    localStorage.lang = lang;
    this.$i18n.locale = lang;
  },
  methods: {
    login() {
      if (this.email && this.password) {
        this.loading = true;
        const data = {
          email: this.email.replace(/ /g, ""),
          password: this.password,
          client_id: Math.floor(Math.random() * Date.now()),
          client_type: "Web",
        };
        this.$axios
            .post("auth/login", data, {noToast: true})
            .then((res) => {
              this.loading = false;
              this.$store.dispatch("login", {
                token: res.data.access_token,
                userData: res.data.user,
              });
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
              this.$toast.error(
                  this.$t("login.TheUsernameOrPasswordIsIncorrect")
              );
            });
      } else {
        this.$toast.error(this.$t("BeSureToEnterTheInformation"));
      }
    },
    getId() {
      console.log(v4());
      return v4();
    },
  },
  created() {
    this.$store.dispatch("logout");
    // console.log(localStorage.getItem('lang'))
    // console.log(localStorage.getItem('darkMode'))
  },
};
</script>
